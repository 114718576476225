import React from 'react';
import { Button, Card } from 'react-bootstrap';
import './style.scss';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

export interface Props {
  title?: string;
  description: React.ReactNode;
  buttonText: string;
  buttonLink: string;
  className?: string;
}

const options: any = {
  renderMark: {
    // eslint-disable-next-line react/display-name
    [MARKS.BOLD]: (text: string) => <strong className="text-primary">{text}</strong>,
  },
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => <p>{children}</p>,
  },
};

export const ReadyForMoreBlocks: React.FC<Props> = ({ title, description, buttonText, buttonLink, className }) => {
  return (
    <Card
      className={`info-card-btn p-5 rounded-sm bg-secondary d-flex flex-column justify-content-between mb-lg-0 mb-4 ${className}`}
    >
      <div className="mb-4">
        <h4 className="text-primary font-weight-bold">{renderRichText(title)}</h4>
        <p>{description ? renderRichText(description, options) : ''}</p>
      </div>
      <footer>
        <Button variant="outline-primary" className="text-primary mt-2" href={buttonLink ? buttonLink : undefined}>
          {buttonText}
        </Button>
      </footer>
    </Card>
  );
};
