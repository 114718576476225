import React from 'react';
import { Card } from 'react-bootstrap';
import './style.scss';

export interface BlockProps {
  title: string;
  description: string;
  hint?: string;
  link?: string;
}

export const Block: React.FC<BlockProps> = ({ title, description, hint, link }) => {
  return (
    <Card className="small-card-block h-100 rounded-sm text-center pt-4 w-100 pb-4">
      {link ? (
        <a className="text-primary my-0 fz-22 font-weight-bold" href={link}>
          {title}
        </a>
      ) : (
        <h5 className="text-primary mb-0">{title}</h5>
      )}
      <p className="text-center pt-4 px-4 pb-0 mb-0">{description}</p>
      {hint ? <p className="text-gray-300 fz-2 hint">{hint}</p> : null}
    </Card>
  );
};
