import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageHeader from '../../components/PageHeader';
import Default from '../../layouts/default';
import { Block } from 'components/BannerWithBlocks/BannerWithBlocks';
import { ReadyForMoreBlocks } from 'components/ReadForMore';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import SEO from 'components/seo';
import { renderRichText } from '../../core/renderRichText';
import { DefaultModal } from '../../foundations/Modals';
import { Helmet } from 'react-helmet';

const Contact: React.FC<{ data: any }> = ({ data }) => {
  const contactData: GatsbyTypes.ContentfulPageConnection = data.contact;
  const contactPage = contactData.nodes[0];
  const seo = contactPage.seo;
  const blocks = contactPage.pageBlocks;
  const blockHeader = blocks?.length && blocks[0];
  const blockContent = blocks?.length && blocks[1];
  const blockTwoContent = blocks?.length && blocks[2];

  const [showModal, setShowModal] = useState(false);
  const [scriptHasLoaded, setScriptHasLoaded] = useState(false);

  const handleModalClick = () => {
    setShowModal(true);
    setScriptHasLoaded(true);
  };

  return (
    <>
      <Helmet defer="false">
        <script type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
        {scriptHasLoaded && (
          <script>
            {`window?.hbspt?.forms?.create({
                region: 'na1',
                portalId: '7478034',
                formId: '77a5c811-63db-4309-a86c-4bcd46278b59',
                target: '#hubspotForm',
              })`}
          </script>
        )}
      </Helmet>
      <Default>
        <SEO data={seo} />
        <PageHeader
          key="header"
          textTop={blockHeader?.textTop || ''}
          textMiddle={blockHeader?.textMiddle}
          textBottom={blockHeader?.textBottom}
          subHeader={<h2 className="h4">Want to chat support, sales, or good hawk puns? We’re all ears.</h2>}
        />
        <div className="container-fluid mb-5">
          <BackgroundImage
            fadeIn
            Tag="article"
            {...convertToBgImage(blockContent.backgroundImage.gatsbyImageData)}
            preserveStackingContext
            data-testid={'contactPageBlock'}
          >
            <div className="container">
              <div key="block" className="row pb-4 justify-content-center">
                <div className="col-12 text-center">
                  <h3 className="h5 text-primary mb-5 mt-6">{blockContent.blockHeader}</h3>
                </div>
                <div className="col-12">
                  <div className="row mb-4">
                    {blockContent.contactBlocks.map(
                      (
                        block: { header: string; description: string; link: string },
                        index: React.Key | null | undefined
                      ) => {
                        return (
                          <div
                            className="mb-4 col-lg col-12 "
                            key={index}
                            onClick={block.header === 'Sales' ? () => handleModalClick() : ''}
                          >
                            <Block title={block.header} description={block.description} link={block.link} />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    {blockContent.contactBlocksRowTwo.map(
                      (
                        block: { header: string; description: string; hint: string | undefined; link: string },
                        index: React.Key | null | undefined
                      ) => {
                        return (
                          <div className="col-12" key={index}>
                            <Block
                              title={block.header}
                              description={block.description}
                              link={block.link}
                              hint={block.hint}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
        <div className="container-fluid bg-asphalt">
          <div className="container">
            <div key="blocktwo" className="row  pb-5">
              <div className="col-12 text-center">
                <h3 className="text-primary mb-5 mt-6 h5">{renderRichText(blockTwoContent?.headline)}</h3>
              </div>

              <div className="col-12">
                <div className="row justify-content-center bg-asphalt">
                  {blockTwoContent.blockItems.map(
                    (
                      block: {
                        headline: string | undefined;
                        content: string;
                        button: any;
                      },
                      index: React.Key | null | undefined
                    ) => {
                      return (
                        <div className="col-12 col-lg mb-4 mb-lg-0" key={index}>
                          <ReadyForMoreBlocks
                            title={block.headline}
                            description={block.content}
                            buttonText={block.button.text}
                            buttonLink={block.button.url}
                            className={'h-100'}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DefaultModal
          className="contact-modal"
          size="lg"
          onHide={() => setScriptHasLoaded(false)}
          showButtons={false}
          show={showModal}
          showModal={setShowModal}
          content={<div className="p-5" id="hubspotForm"></div>}
        />
      </Default>
    </>
  );
};

export const query = graphql`
  {
    contact: allContentfulPage(filter: { page: { eq: "Contact" } }) {
      nodes {
        seo {
          ...ContentfulSeo
        }
        ...defaultFields
      }
    }
  }
`;

export default Contact;
